import React, { useRef, useState } from "react"
import { connect } from "react-redux"
import { Layout, FlexRow } from "@components/layout"

import {
  JSONLD,
  Graph,
  Product as DataProduct,
  ItemReviewed,
  Review,
  Author,
  Location,
  Rating,
  Generic,
} from "react-structured-data"

// import { Modal, ScalableImage } from '@components/shared'

import { SimpleProduct, VariableProduct } from "@components/ecommerce"

import { cartActions } from "@stores"
// import './styles/product.scss'

// const selectProductType = (type)=>{
//   switch(type) {
//     case "SIMPLE":
//       return SimpleProduct
//     case 'VARIABLE':
//       return VariableProduct
//     default:
//       return SimpleProduct
//   }
// }

// const customSliderSettings = {
// 	slidesToShow: 4,
// 	responsive: [],
// }

const Product = ({ pageContext, addToCart, store }) => {
  const {
    product,
    nextProduct,
    prevProduct,
    prevCategoryProduct,
    nextCategoryProduct,
    category,
  } = pageContext
  console.log(product)
  return (
    <Layout {...product}>
      <JSONLD>
        <DataProduct
          name={product.name}
          description={product.description}
          aggregateRating={product.reviews.averageRating}
          slogan={product.shortDescription}
        ></DataProduct>
      </JSONLD>
      <SimpleProduct
        product={product}
        addToCart={addToCart}
        cart={store.items}
      />
    </Layout>
  )

  // const modal = useRef(null)
  // const [galleryElement, setGalleryElement] = useState({})

  // const ProductType = selectProductType(product.type)
  // let _url = galleryElement.sourceUrl
  // return (
  // 	<React.Fragment>
  // 	<Modal title={'Podgląd zdjęcia'} ref={modal}
  // 		onClose={()=>{
  // 			setGalleryElement({})
  // 		}}
  // 	>
  // 		{_url && <Zoom sourceUrl={_url}/>}
  // 		{/* {galleryElement.srcSet && <ScalableImage srcSet={galleryElement.srcSet} />} */}
  // 		</Modal>
  // 	<Layout
  // 		seo={pageContext.product.seo}
  // 		siteMetaData={pageContext.product.siteMetaData}
  // 	>

  // 		<AddProductToLastSeen product={product}/>
  // 		<Inner
  // 			product={product}
  // 			nextProduct={nextProduct}
  // 			prevProduct={prevProduct}
  // 			prevCategoryProduct={prevCategoryProduct}
  // 			nextCategoryProduct={nextCategoryProduct}
  // 		>
  // 		<List>
  // 		<ProductType
  // 			product={product}
  // 			addToCart={addToCart}
  // 			resetCart={resetCart}
  // 			onGalleryClick={(element) => {
  // 				setGalleryElement(element)
  // 				modal.current.toggle()
  // 			}}
  // 		/>
  // 		</List>
  // 	</Inner>
  // 	</Layout>

  // 	</React.Fragment>
  // )
}

const mapStateToProps = (state) => ({ store: state.Cart })

const mapActionToDispatch = { ...cartActions }

export default connect(mapStateToProps, mapActionToDispatch)(Product)
